import "./src/styles/index.scss";
import React from 'react'
import Wrapper from "./src/components/Wrapper/wrapper";
import { Amplify, Auth } from "aws-amplify";

Amplify.configure({
    Auth: {
        // REQUIRED - Amazon Cognito Region
        region: 'us-east-2:387014705563',

        // OPTIONAL - Amazon Cognito Federated Identity Pool Region
        // Required only if it's different from Amazon Cognito Region
        identityPoolRegion: 'us-east-2_Hs5ZhXgNL',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'us-east-2_Hs5ZhXgNL',

        userPoolWebClientId: '111plrue04f542hb2hrpfgirgu',
    }
});

export const onRouteUpdate = async ({ location, _ }) => {
    if (location.pathname.startsWith("/northstar/") && !location.pathname.startsWith("/northstar/about")) {
        switch (process.env.GATSBY_ENV) {
            case "dev":
            case "devel":
            case "develop":
            case "development":
                window.location.href = "https://northstar-uat.bamelevate.com";
                break;

            default:
                window.location.href = "https://northstar.bamelevate.com";
        }
    }
}

// You can get the current config object
const currentConfig = Auth.configure();

export const wrapRootElement = ({ element }) => {
    return (
        <Wrapper>
            {element}
        </Wrapper>
    )
}
