import React from "react"

interface HelpButtonType {
    name: string,
    content: any
}

const helpButtonText: { [key: string]: HelpButtonType } = {
    homePage: {
        name: "homePage",
        content:
            <div>
                <div>
                    The Portfolio Company Platform puts the power of BAM Elevate’s data science capabilities in the hands of our portfolio companies by delivering data tools and insights.
                    <br></br>
                    <br></br>
                    To get started, use the navigation and menu to select a data tool.
                    <br></br>
                    <br></br>
                    To log out, click the Sign Out button in the upper-right.
                </div>
            </div>
    },
    gtmModule: {
        name: "gtmModule",
        content:
            <div>
                The Go-to-Market module ranks sales opportunities using a proprietary data science model focused on competing products and integrates with Rocket Reach contact lists, so that users can find high-potential leads and operationalize those directly into the sales process.
                <br></br>
                <br></br>
                To get started, use the filters to identify targets who are using competing products.
                <br></br>
                Review the information about those targets, including alerts about recent hires, firm size, and more.
                <br></br>
                <br></br>
                To Action the leads, left click to:
                <ol>
                    <li>1. Visit the company website</li>
                    <li>2. View contacts on RocketReach</li>
                </ol>
                <br></br>
                From RocketReach, use contact lists to review and export leads into your process.
            </div>
    }
}

export default helpButtonText