import React, { useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { AllowCookiesProvider } from '../../contexts/cookiesContext';
import CookieConsent from 'react-cookie-consent';
import { navigate } from 'gatsby';
import HelpButtonContext from '../../contexts/helpContext';
import helpButtonText from '../../contexts/helpButtonText';

const Wrapper = (props) => {
    const [userConsent, setUserConsent] = useState(true);
    const [helpContext, updateHelpContext] = useState(helpButtonText.homePage);
    const value = {helpContext, updateHelpContext};

    return (
        <AllowCookiesProvider value={userConsent}>
            <HelpButtonContext.Provider value={value}>

                <CookieConsent
                    onAccept={() => setUserConsent(true)}
                    location="bottom"
                    buttonText={
                        <div className="text-orange-light text-xs lg:text-sm">
                            x
                        </div>
                    }
                    style={{
                        background: 'rgba(248,248,248,0.9)',
                        justifyContent: "flex-end",
                        placeItems: "center"
                    }}
                    buttonStyle={{ background: 'none' }}
                    cookieName="userConsentCookie"
                >
                    <div className="text-center text-xs lg:text-sm text-blue">
                        <div>
                            Our website uses cookies to give you the best browsing experience. By using this website, you consent to our use of cookies.
                            For more information, please read our{' '}
                            <span className="text-orange hover:underline cursor-pointer" onClick={() => navigate("/privacy_policy")}>Privacy Policy</span>
                            .
                        </div>
                    </div>
                </CookieConsent>
                {
                    userConsent ?
                        <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_SITE_KEY}>
                            {props.children}
                        </GoogleReCaptchaProvider>
                        :
                        <div>
                            {props.children}
                        </div>
                }
            </HelpButtonContext.Provider>

        </AllowCookiesProvider>

    )
}

export default Wrapper;